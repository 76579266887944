export type Program =
  | 'ACT'
  | 'FLEOC'
  | 'KSA'
  | 'LEAP'
  | 'MAAP'
  | 'NCEOC'
  | 'OCCT'
  | 'SAT'
  | 'STAAR'
  | 'TCAP'
  | 'TSIA2'

export type OrgFilter = {
  studentId?: string
  classId?: string
  teacherId?: string
  schoolId?: string
  districtId?: string
}

export type Scope = 'CLASS' | 'DISTRICT' | 'SCHOOL' | 'STUDENT' | 'SYSTEM' | 'TEACHER'

export type RefScope = {
  student_id?: string
  class_id?: string
  teacher_id?: string
  school_id?: string
  district_id?: string
  scope: Scope
}

export enum ResponseByPracticeTestValueType {
  RESPONSE_VALUE = 'RESPONSE_VALUE',
  SEQ_RESPONSE_VALUE = 'SEQ_RESPONSE_VALUE',
  POINT_VALUE = 'POINT_VALUE',
}

// ResponseValue
export type ResponseByPracticeTestResponseValue = {
  response: string
  count: number
}

// SeqResponseValueType
export type ResponseByPracticeTestSeqResponseValueSeqCount = {
  sequence: number
  count: number
}
export type ResponseByPracticeTestSeqResponseValue = {
  response: string
  seqCounts: ResponseByPracticeTestSeqResponseValueSeqCount[]
}

// PointValue
export type ResponseByPracticeTestPointValue = {
  points: number
  count: number
}

export type ResponseByPracticeTestValue =
  | ResponseByPracticeTestResponseValue
  | ResponseByPracticeTestPointValue
  | ResponseByPracticeTestSeqResponseValue

export type ResponsesByPracticeTestItemResponseDeclaration = {
  responseDeclarationId: string
  responseType: ResponseByPracticeTestValueType
  responses: ResponseByPracticeTestValue[]
}

export type ResponsesByPracticeTestItem = {
  itemId: string
  interactionType: string
  responseDeclarations: ResponsesByPracticeTestItemResponseDeclaration[]
}

export type ResponsesByPracticeTest = {
  studentCount: number
  items?: ResponsesByPracticeTestItem[]
}

export type PracticeTestAssessmentKeyItem = {
  index: number
  itemId: string
  nodeId: string
  passageId?: string
  responses: any[]
}

export type EventData = {
  course?: string
  item?: string
  module?: string
  product?: string
  program?: string
  url?: string
}

export type GetFeedbackPrompt = {
  event: string
  eventData?: EventData
}

export type FeedbackPrompt = {
  promptText?: string
  promptType?: string
  responseId?: string
  showPrompt: boolean
}

export type SaveFeedbackPrompt = {
  followUp?: boolean
  followUpEmail?: string
  promptResponse: string
  responseId: string
  textResponse?: string
}
