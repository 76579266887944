import { FC, useContext, useMemo } from 'react'
import { AnswerBubble, CheckboxComponent } from '@app/components/ui'
import { STAAR_MULTIPLE_CHOICE_ANSWER } from '@app/constants'
import { onKeyboardSubmit, percentageFormatter } from '@app/helpers'
import { QuestionContextMenuContext } from '@app/components/common'
import { BubbleWrapper, ContentWrapper, PercentageLabel, SimpleChoiceWrapper } from './styles'

export interface SimpleChoiceProps {
  letterIndex?: string
  itemId: string
  identifier?: string
  fixed?: boolean
  isCorrect?: boolean
  groupMode?: boolean
  feedbackPercentage?: number
  feedbackFormatter?: (percentage: number) => string
  allowMultiple?: boolean
  onSelect?: (id: string) => void
  selectedChoices?: string[]
  children?: JSX.Element
  accessibilityAttr?: { [key: string]: string }
  disabled?: boolean
  setFocusedChoice?: (identifier: string) => void
  isFocused?: boolean
  blankBubble?: boolean
}

const defaultFeedbackFormatter = (percentage: number) => percentageFormatter.format(percentage)

export const SimpleChoiceComponent: FC<SimpleChoiceProps> = props => {
  const {
    letterIndex,
    identifier,
    allowMultiple,
    isCorrect,
    groupMode = false,
    feedbackPercentage,
    feedbackFormatter = defaultFeedbackFormatter,
    onSelect,
    setFocusedChoice,
    isFocused,
    selectedChoices = [],
    accessibilityAttr,
    disabled,
    blankBubble = false,
  } = props

  const { stikethroughAnswers = [] } = useContext(QuestionContextMenuContext)
  const isStrikethrough = stikethroughAnswers.includes(identifier)

  const selected = useMemo(() => selectedChoices.includes(identifier), [selectedChoices, identifier])
  const isReviewMode = isCorrect !== undefined || groupMode

  const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onSelect?.(identifier)
    setFocusedChoice && setFocusedChoice(identifier)
  }

  const onKeyboardSubmitHandler = () => onSelect?.(identifier)

  return (
    <SimpleChoiceWrapper
      isFocused={isFocused}
      isReviewMode={isReviewMode}
      disabled={!selected && disabled}
      className={STAAR_MULTIPLE_CHOICE_ANSWER}
      id={identifier}
      onClick={onClickHandler}
      onKeyDown={onKeyboardSubmit(onKeyboardSubmitHandler)}
    >
      {groupMode && feedbackPercentage !== undefined && (
        <PercentageLabel correct={isCorrect} as='span'>
          {feedbackFormatter(feedbackPercentage)}
        </PercentageLabel>
      )}
      <BubbleWrapper blankBubble={blankBubble}>
        {allowMultiple && !letterIndex ? (
          <CheckboxComponent
            disabled={!selected && (disabled || isReviewMode)}
            checked={selected}
            correct={isCorrect}
            identifier={identifier}
            accessibilityAttr={{ ...accessibilityAttr }}
          />
        ) : (
          <AnswerBubble
            selected={selected}
            correct={isCorrect}
            symbol={letterIndex}
            identifier={identifier}
            accessibilityAttr={accessibilityAttr}
            tabIndex={disabled || isReviewMode ? null : 0}
          />
        )}
      </BubbleWrapper>
      <ContentWrapper id={identifier} isStrikethrough={isStrikethrough}>
        {props.children}
      </ContentWrapper>
    </SimpleChoiceWrapper>
  )
}
