import { useEffect, useMemo, useState } from 'react'
import { useLocation, useSearchParams } from "react-router-dom"
import { getRandomInteger } from '../utils'
import { EventData, FeedbackPrompt, User } from '@app/types'
import { getFeedbackPrompt } from '@app/services/feedback'
import { Roles } from '@app/constants'

const MIN = 1
const MAX = 3
const CHANCE = 1
const MIN_FEEDBACK_INTERVAL = 30 * (60 * 1000) // milliseconds
const FEEDBACK_TIMESTAMP_KEY = 'mp_last_feedback'

export enum FeedbackEvent {
  VIEW_REPORT = 'View a Report',
  VIEW_STUDY_PLAN = 'View a Study Plan',
  EDIT_STUDY_PLAN = 'Edit a Study Plan',
  REVIEW_TEST_RESULTS = 'Review Test Results',
  USE_MANAGE_MODULE = 'Use Manage Module',
  COMPLETE_PRACTICE_TEST = 'Complete a Practice Test',
  COMPLETE_SNAP_COURSE = 'Complete a SnapCourse',
  COMPLETE_MASTERY_LESSON = 'Complete a Mastery Lesson',
  COMPLETE_STUDY_PLAN = 'Complete A Study Plan',
  COMPLETE_ALL_STUDY_PLANS = 'Complete All Study Plans',
}

const FEEDBACK_EVENT_ROLES = {
  'View a Report': Roles.Teacher,
  'View a Study Plan': Roles.Teacher,
  'Edit a Study Plan': Roles.Teacher,
  'Review Test Results': Roles.Teacher,
  'Use Manage Module': Roles.Teacher,
  'Complete a Practice Test': Roles.Student,
  'Complete a SnapCourse': Roles.Student,
  'Complete a Mastery Lesson': Roles.Student,
  'Complete A Study Plan': Roles.Student,
  'Complete All Study Plans': Roles.Student,
  'Sitewide Feedback': '',
}

const checkEventRole = (user: User, event: FeedbackEvent): boolean => {
  const feedbackEventRole = FEEDBACK_EVENT_ROLES[event]

  // Empty event role means event is available for any role.
  if (!feedbackEventRole) return true

  if (feedbackEventRole !== Roles.Teacher) {
    return user.role === feedbackEventRole
  }

  // Feedback requested for teacher and "teacher" school admins too.
  return user.role === Roles.Teacher || user?.isTeacher
}

const shouldRequestFeedback = (user: any, event: FeedbackEvent): boolean => {
  if (!user || !event) {
    return false
  }

  // Check if user has correct role for the event.
  if (!checkEventRole(user, event)) {
    return false
  }

  // Roll for probability.
  const roll = getRandomInteger(MIN, MAX)

  if (roll > CHANCE) {
    return false
  }

  // Check last feedback timestamp.
  const lastFeedbackTimestamp = localStorage.getItem(`${FEEDBACK_TIMESTAMP_KEY}_${user.id}`)

  // No timestamp means we haven't asked for a feedback from this user yet.
  if (!lastFeedbackTimestamp) {
    return true
  }

  // Check that last feedback request was no less than [interval] ago.
  return Date.now() - Number(lastFeedbackTimestamp) >= MIN_FEEDBACK_INTERVAL
}

const useFeedbackEventData = (testName: string): EventData => {
  const location = useLocation()
  const [searchParams] = useSearchParams()

  return useMemo(() => ({
    product:'qti-player',
    item: testName || null,
    course: searchParams.get('subject') || null,
    program: searchParams.get('program') || null,
    url: location.pathname
  }), [location.pathname, searchParams, testName])
}

type UseFeedbackRequest = {
  loading: boolean
  feedbackPrompt?: FeedbackPrompt
}

type AdditionalProperties = {
  testName?: string
}

export const useFeedbackRequest = (user: User, event: FeedbackEvent, additionalProperties?: AdditionalProperties): UseFeedbackRequest => {
  const {
    testName
  } = additionalProperties

  const [loading, setLoading] = useState<boolean>(false)
  const [feedbackPrompt, setFeedbackPrompt] = useState<FeedbackPrompt>(null)

  const eventData = useFeedbackEventData(testName)

  useEffect(() => {
    setLoading(true)

    const shouldRequest = shouldRequestFeedback(user, event)

    if (!shouldRequest) return

    getFeedbackPrompt({ event, eventData })
      .then(feedbackPrompt => {
        setFeedbackPrompt(feedbackPrompt)
        localStorage.setItem(`${FEEDBACK_TIMESTAMP_KEY}_${user.id}`, Date.now().toString())
      })
      .catch(error => {
        console.warn('[useFeedbackRequest]', error)
      })
      .finally(() => setLoading(false))
  }, [user, event, eventData])

  return { loading, feedbackPrompt }
}
