import { alpha, Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import { colorPalette } from '@app/components/ui/containers/AlertContainer/helpers'
import { focusStyle } from '@app/theme'

type SimpleChoiceProps = BoxProps & {
  isFocused?: boolean
  isReviewMode?: boolean
  disabled?: boolean
}

const STYLED_PROPS = ['isFocused', 'isStrikethrough', 'isReviewMode', 'disabled']

export const SimpleChoiceWrapper = styled(Box, {
  shouldForwardProp: prop => !STYLED_PROPS.includes(prop as string),
})<SimpleChoiceProps>(({ theme, isFocused = false, isReviewMode = false, disabled = false }) => ({
  padding: theme.spacing(3),
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: `${theme.shape.borderRadius * 1.5}px`,
  border: `1px solid ${theme.palette.blue[100]}`,

  ...(isFocused && {
    ...focusStyle(theme).border,
  }),
  ...(isReviewMode || disabled
    ? {
        border: `1px solid ${theme.palette.grey[100]}`,
      }
    : {
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: theme.palette.blue[50],
          border: `1px solid ${theme.palette.blue[50]}`,

          [theme.breakpoints.down('mobile')]: {
            ...focusStyle(theme).border,
          },
        },

        ':focus-within': {
          ...focusStyle(theme).border,
        },
      }),

  ...(disabled &&
    !isReviewMode && {
      [`& ${ContentWrapper}`]: {
        color: theme.palette.grey[500],
      },
    }),

  [theme.breakpoints.down('mobile')]: {
    padding: theme.spacing(2),
  },
}))

export const BubbleWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'blankBubble',
})<
  BoxProps & {
    blankBubble?: boolean
  }
>(({ blankBubble = false }) => ({
  ...(blankBubble && {
    visibility: 'hidden',
  }),
}))

export const ContentWrapper = styled(Box)<{ isStrikethrough?: boolean }>(
  ({ theme, isStrikethrough = false }) => ({
    width: '100%',
    color: theme.palette.grey[800],
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginLeft: theme.spacing(6),
    position: 'relative',

    ...(isStrikethrough && {
      color: alpha(theme.palette.grey[800], 0.6),

      '& > div': {
        opacity: 0.6,
      },
    }),

    ...(isStrikethrough && {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%',
        height: '2px',
        backgroundColor: theme.palette.grey[400],
        zIndex: 1,
      },
    }),

    '& p': {
      marginBottom: '0 !important',
    },

    [theme.breakpoints.down('mobile')]: {
      marginLeft: theme.spacing(4.25),
    },
  }),
)

export const PercentageLabel = styled(Typography)<TypographyProps & { correct?: boolean }>(
  ({ theme, correct }) => ({
    fontWeight: 500,
    color: colorPalette(theme, correct ? 'success' : 'warning').color,
    whiteSpace: 'pre',
    marginRight: theme.spacing(4),
  }),
)
