import React from 'react'
import { useSnapshot } from 'valtio'
import { UserState } from '@app/storage'
import { FeedbackEvent, useFeedbackRequest, useFeedbackSubmit } from '@app/helpers'
import StudentFeedbackView from './StudentFeedbackView'

interface Props {
  event: FeedbackEvent
  testName: string
}

const StudentFeedbackModalContent: React.FC<Props> = ({ event, testName }) => {
  const { user } = useSnapshot(UserState)

  const { loading, feedbackPrompt } = useFeedbackRequest(user, event, { testName })

  const { selectedRate, onChangeFeedbackRating, submitted } = useFeedbackSubmit(feedbackPrompt)

  if (loading || !feedbackPrompt?.showPrompt) {
    return <></>
  }

  return (
    <StudentFeedbackView
      selectedRate={selectedRate}
      onChangeFeedbackRating={onChangeFeedbackRating}
      disabled={submitted}
      feedbackText={feedbackPrompt?.promptText}
    />
  )
}

export default StudentFeedbackModalContent
